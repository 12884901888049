import { stripMaybes } from '@emico-utils/graphql-data-utils'
import styled from '@emotion/styled'

import { minWidth } from '@emico/styles'

import { CategoryItem } from '../lib/customTypes'
import { DefaultLayoutStaticData } from '../lib/useCraftGlobalSets'
import { useWishlist } from '../lib/useWishlist'
import theme from '../theme'
import CategoryPromotionBanner from './CategoryPromotionBanner'
import ProductCard from './ProductCard'
import { WishlistIconButton } from './WishlistIconButton'

const ProductList = styled.ul`
  --category-spacing: ${theme.spacing.md};
  display: grid;
  gap: var(--category-spacing);
  margin: 0;
  padding: 0;
  list-style: none;

  @media ${minWidth('md')} {
    grid-template-columns: repeat(var(--category-columns, 2), 1fr);
    --category-spacing: ${theme.spacing.lg};
  }
`

interface Props extends DefaultLayoutStaticData {
  items: CategoryItem[]
  disableEcommerce: boolean
}

const CategoryProductList = ({
  websiteData,
  items,
  disableEcommerce,
}: Props) => {
  const { getItemBySku, wishlist } = useWishlist()

  return (
    <ProductList>
      {items?.map((item) => {
        switch (item.__typename) {
          case 'CraftCategoryBannersBlockEntry':
            return (
              <li key={item.bannerTitle}>
                <CategoryPromotionBanner banner={item} />
              </li>
            )
          default:
            return 'uid' in item ? (
              <li key={item.uid}>
                <ProductCard
                  websiteData={websiteData}
                  disableEcommerce={disableEcommerce}
                  wishlistIcon={
                    <WishlistIconButton
                      product={item}
                      wishlistId={wishlist?.id ?? ''}
                      wishlistItem={getItemBySku(item.sku)}
                      analyticsContext="category.product.list"
                      analyticsName="wishlist"
                    />
                  }
                  name={item.name}
                  url={item.urlKey}
                  image={{
                    url: item.smallImage?.url ?? '',
                    alt: item.name ?? '',
                  }}
                  highlightedAttributes={
                    item.highlightedAttributesPlp?.filter(stripMaybes) ?? []
                  }
                  reviewRating={item.ratingSummary ?? 0}
                  reviewCount={item.reviewCount ?? 0}
                  preorder={item.preorder}
                  stockStatus={item.stockStatus}
                  regularPrice={item.priceRange.minimumPrice.regularPrice}
                  finalPrice={item.priceRange.minimumPrice.finalPrice}
                  percentOff={item.priceRange.minimumPrice.discount?.percentOff}
                  productLabels={item.productLabels?.filter(stripMaybes)}
                  isMainProduct={
                    item?.isMainProduct ||
                    item?.__typename === 'ConfigurableProduct'
                  }
                  analyticsContext="categoryPage"
                />
              </li>
            ) : null
        }
      })}
    </ProductList>
  )
}

export default CategoryProductList
