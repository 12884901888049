import { Image } from '@emico-react/image'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import { ComponentProps, ReactNode } from 'react'

import {
  HighlightAttribute,
  Maybe,
  Money,
  PreOrder,
  ProductStockStatus,
} from '@emico/graphql-schema-types'
import { CheckmarkRoundIcon } from '@emico/icons'
import { ProductLabelListFragment } from '@emico/product-label-fragment'
import { minWidth } from '@emico/styles'
import { sortArrayByProperty } from '@emico/utils'

import CrossRoundIcon from '../icons/CrossRoundIcon'
import getIsPreorder from '../lib/getIsPreorder'
import { DefaultLayoutStaticData } from '../lib/useCraftGlobalSets'
import theme from '../theme'
import CraftLink from './CraftLink'
import NextLink from './NextLink'
import ProductCardLink from './ProductCardLink'
import ProductLabels from './ProductLabels'
import RegularFinalPrice from './RegularFinalPrice'
import StarRatingWithTotals from './StarRatingWithTotals'

const Card = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: ${theme.borderRadius.base};
  color: var(--product-card-color, ${theme.colors.onBackground});
  transition-property: ${theme.transition.properties.common};
  transition-duration: ${theme.transition.durations.normal};
  transition-timing-function: ${theme.transition.timingFunctions.easeInOut};

  @media ${minWidth('lg')} {
    ${theme.animation.zoomNestedImage(
      1.05,
      theme.transition.durations.extraSlow,
    )};
  }
`

const ImageWrapper = styled.div`
  position: relative;
  background: var(
    --product-card-image-wrapper-background,
    ${theme.colors.grayLight}
  );
`

const Figure = styled('figure', {
  shouldForwardProp: (prop) => !['hasWishlistIcon'].includes(prop.toString()),
})<{ hasWishlistIcon?: boolean }>`
  text-align: center;
  overflow: hidden;
  margin: 0;
  padding: ${theme.spacing.lg} ${theme.spacing.sm};
  padding: ${({ hasWishlistIcon }) =>
    hasWishlistIcon
      ? `0 ${theme.spacing.sm} ${theme.spacing['2xl']}`
      : `${theme.spacing.lg} ${theme.spacing.sm}`};

  @media ${minWidth('lg')} {
    padding: ${({ hasWishlistIcon }) =>
      hasWishlistIcon
        ? `0 ${theme.spacing.sm} ${theme.spacing['2xl']}`
        : `${theme.spacing.xl} ${theme.spacing.sm}`};
  }
`

const StyledImage = styled(Image)`
  width: 100%;
  height: auto;
  aspect-ratio: 16 / 9;
  object-fit: contain;
`

const NameWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: ${theme.spacing.md};
`

const ProductName = styled.span`
  display: flex;
  align-items: flex-end;
  margin-bottom: ${theme.spacing.xs};
  color: ${theme.colors.text};
  font-weight: ${theme.fontWeights.bold};
  text-transform: uppercase;
`

const StyledProductLabels = styled(ProductLabels)`
  margin: ${theme.spacing.sm};
`

const StyledRegularFinalPrice = styled(RegularFinalPrice)`
  --price-font-size: ${theme.fontSizes.md};
  margin-left: ${theme.spacing.sm};
  color: ${theme.colors.text};
`

const StyledStarRatingWithTotals = styled(StarRatingWithTotals)`
  margin-bottom: ${theme.spacing.sm};
  color: ${theme.colors.text};
`

const HighlightedAttributes = styled.ul`
  margin: 0 0 ${theme.spacing.sm};
  padding: 0;
  list-style: none;
`

const HighlightedAttribute = styled.li`
  display: inline;
  color: ${theme.colors.text};
  font-size: ${theme.fontSizes.sm};

  &:not(:first-of-type) {
    &::before {
      content: '\0020 \007C \0020';
    }
  }
`

const AvailabilityText = styled('span', {
  shouldForwardProp: (prop) => !['isOutOfStock'].includes(prop.toString()),
})<{ isOutOfStock?: boolean }>`
  display: flex;
  align-items: center;
  color: ${({ isOutOfStock }) =>
    isOutOfStock ? theme.colors.grayBrown : theme.colors.success};
  font-size: ${theme.fontSizes.sm};
  font-weight: ${theme.fontWeights.bold};
`

const iconStyles = css`
  margin-right: ${theme.spacing.xs};
  font-size: 15px;
`

const StyledCheckmarkRoundIcon = styled(CheckmarkRoundIcon)`
  ${iconStyles};
`

const StyledCrossRoundIcon = styled(CrossRoundIcon)`
  ${iconStyles};
`

const PreOrderText = styled.span`
  color: ${theme.colors.text};
  font-size: ${theme.fontSizes.sm};
  font-weight: ${theme.fontWeights.bold};
`

const Time = styled.time`
  font-weight: ${theme.fontWeights.normal};
`

const WishlistIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0;
  padding: ${theme.spacing.md} ${theme.spacing.md} 0;
`

const StyledCraftLink = styled(CraftLink)`
  color: ${theme.colors.text};

  &:hover {
    color: ${theme.colors.text};
  }
`

interface ImageProps {
  url: string
  alt?: string
  width?: number
  height?: number
}

interface Props
  extends Pick<ComponentProps<typeof NextLink>, 'onClick'>,
    DefaultLayoutStaticData {
  name: string
  wishlistIcon?: ReactNode
  image: ImageProps
  url: string
  highlightedAttributes: HighlightAttribute[]
  reviewRating: number
  reviewCount: number
  preorder: Maybe<PreOrder>
  stockStatus: Maybe<ProductStockStatus>
  regularPrice: Maybe<Money>
  finalPrice: Maybe<Money>
  percentOff?: Maybe<number>
  disableEcommerce: boolean
  isMainProduct: boolean
  analyticsContext: string
  productLabels: ProductLabelListFragment[] | undefined
}

const ProductCard = ({
  websiteData,
  disableEcommerce,
  name,
  wishlistIcon,
  image,
  url,
  highlightedAttributes,
  reviewRating,
  reviewCount,
  preorder,
  stockStatus,
  regularPrice,
  finalPrice,
  percentOff,
  isMainProduct,
  onClick,
  analyticsContext,
  productLabels,
  ...other
}: Props) => {
  const isPreorder = getIsPreorder(preorder)

  const sortedHighlightedAttributes = sortArrayByProperty(
    highlightedAttributes ?? [],
    (attribute) =>
      attribute?.attributePosition || attribute?.attributePosition === 0
        ? attribute.attributePosition
        : null,
  ).filter((attribute) => attribute.attributeValue)

  const craftEcommerceGlobalSet = websiteData?.find(
    (globalSet) => globalSet?.__typename === 'CraftEcommerceGlobalSet',
  )

  const disabledEcommerceLink =
    craftEcommerceGlobalSet?.__typename === 'CraftEcommerceGlobalSet'
      ? craftEcommerceGlobalSet.disabledEcommerceLink
      : undefined

  return (
    <Card {...other}>
      <ImageWrapper>
        {productLabels && productLabels.length !== 0 && (
          <StyledProductLabels productLabels={productLabels} />
        )}

        {wishlistIcon && (
          <WishlistIconWrapper>{wishlistIcon}</WishlistIconWrapper>
        )}

        <ProductCardLink
          href={url}
          productName={name}
          analyticsContext={analyticsContext}
          onClick={onClick}
        >
          <Figure hasWishlistIcon={Boolean(wishlistIcon)}>
            <StyledImage
              url={image.url}
              alt={name}
              lazy={false}
              sizes={theme.imageSizes.productCard.sizes}
            />
          </Figure>
        </ProductCardLink>
      </ImageWrapper>

      <ProductCardLink
        href={url}
        productName={name}
        analyticsContext={analyticsContext}
        onClick={onClick}
      >
        <NameWrapper>
          <ProductName>{name}</ProductName>

          {!isMainProduct && (
            <StyledRegularFinalPrice
              regularPrice={regularPrice}
              finalPrice={finalPrice}
              percentOff={percentOff}
            />
          )}
        </NameWrapper>

        <StyledStarRatingWithTotals
          value={reviewRating}
          totalRecords={reviewCount}
          showTotalRecordsShort
        />

        {sortedHighlightedAttributes.length !== 0 && (
          <HighlightedAttributes>
            {sortedHighlightedAttributes.map((attribute) => (
              <HighlightedAttribute key={attribute.attributeCode}>
                {attribute.attributeLabel}&#x3a; {attribute.attributeValue}
              </HighlightedAttribute>
            ))}
          </HighlightedAttributes>
        )}

        {!disableEcommerce &&
          (isPreorder && preorder?.atpDate && preorder.atpDateFormatted ? (
            <PreOrderText>
              <Trans>
                Available from:{' '}
                <Time dateTime={preorder.atpDate}>
                  {preorder?.atpDateFormatted}
                </Time>
              </Trans>
            </PreOrderText>
          ) : stockStatus === ProductStockStatus.IN_STOCK ? (
            <AvailabilityText>
              <StyledCheckmarkRoundIcon />

              <Trans>Directly available</Trans>
            </AvailabilityText>
          ) : (
            <AvailabilityText isOutOfStock>
              <StyledCrossRoundIcon />

              <Trans>Currently not available</Trans>
            </AvailabilityText>
          ))}
      </ProductCardLink>

      {disableEcommerce && (
        <div>
          <span>
            <Trans>Not available online.</Trans>{' '}
          </span>

          {disabledEcommerceLink?.url && disabledEcommerceLink.customText ? (
            <StyledCraftLink craftLink={disabledEcommerceLink}>
              {disabledEcommerceLink.customText}
            </StyledCraftLink>
          ) : (
            <Trans>Find a store in your area.</Trans>
          )}
        </div>
      )}
    </Card>
  )
}

export default ProductCard
